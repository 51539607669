<template>
  <div class="page">
    <!--    <swiper-slide>-->
    <!--      <Navbar></Navbar>-->
    <div class="pageItem">
<!--      <div class="left">   &lt;!&ndash; 左边部分 &ndash;&gt;
        <div class="top">
          <img src="/img/CDLXWM.png" alt="">
        </div>
&lt;!&ndash;        <div class="more">&ndash;&gt;
&lt;!&ndash;          <div>更多</div>&ndash;&gt;
&lt;!&ndash;        </div>&ndash;&gt;
      </div>-->
      <div style="overflow: hidden">
        <div class="content">
          <div class="topImg">
            <img src="/img/swiperPage/DT.png" alt="">
<!--            <img src="../../assets/img/index22.png" alt="">-->
          </div>

          <div class="bottom">
            <div class="b_left">
              <div class="l_title">度假区咨询 <span>输入您要咨询的内容，平台会联系您</span></div>
              <div class="form">
                <el-form ref="form" :model="form" >
                  <el-form-item label="" class="inp">
                    <el-input v-model="form.name" placeholder="请输入您的联系方式"></el-input>
                  </el-form-item>
                  <el-form-item label="" class="tex">
                    <el-input type="textarea" v-model="form.desc" placeholder="请输入您要咨询的内容"></el-input>
                  </el-form-item>
                  <el-form-item label="" class="tex">
                    <el-button class="btn" @click="submit">提  交</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="b_right">
              <div class="r_title">无锡江南古运河旅游度假区</div>
              <div class="r_title_text">欢迎来到无锡江南古运河旅游度假区！了解度假区更多消息</div>
              <div class="wire"></div>
              <div class="contact">
                <div class="c_info c_info1">
                  <div>咨询电话:<span>0510-xxxxxxx</span></div>
                  <div>投诉电话:<span>0510-xxxxxxx</span></div>
                  <div>救助电话:<span>0510-xxxxxxx</span></div>
                </div>
                <div class="c_info">
                  <div>传真:<span>0510-12345678</span></div>
                  <div>邮编:<span>0510-123456</span></div>
                  <div>邮箱:<span>yangxian_trip@163.com</span></div>
                </div>
              </div>
              <div class="path">地址: <span>江苏省无锡市</span></div>
            </div>
          </div>


        </div>
      </div>

    </div>
    <!--    </swiper-slide>-->
<!--    <div class="fontTaggle">-->
<!--      <div :class="{'font':true,'active':fontToggle == '1'}" @click="fontToggle = '1'">中</div>-->
<!--      <div :class="{'font':true,'active':fontToggle == '2'}" @click="fontToggle = '2'">英</div>-->
<!--    </div>-->
  </div>
</template>

<script>
import Navbar from '@/components/navbar/navbar'
export default {
  name: "homeSixBackups",
  components:{
    Navbar,
  },
  data() {
    return {
      fontToggle: '1',
      form:{
        name:''
      }
    }
  },
  created() {
    // sessionStorage.setItem('RightImg',require('../../../public/img/swiperPage/DTZXCBT.png'))
  },
  methods:{
    submit(){

    }
  }
}
</script>

<style scoped lang="less">
.page{
  position: relative;
  height: 100%;
  // 页面主体
  .pageItem{
    //padding: 0 150px 0 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    box-sizing: border-box;
    .left{
      width: 235px;
      height: 700px;
      padding-top: 55px;
      margin-right: 60px;
      margin-left: 55px;
      //background: yellow;
      .top{
        width: 100%;
        height: 500px;
        img{
          width: 100%;
        }
      }
      .more{
        width: 18px;
        height: 130px;
        border-radius: 27px;
        border: 1px solid #337D8D;
        text-align: center;
        //line-height: 130px;
        padding: 0 20px;
        box-sizing: content-box;
        margin: auto;
        div{
          height: 130px;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          line-height: 20px;
          color: #337D8D;
        }
      }
    }
    .content{
      width: 1420px;
      //height: 800px;
      overflow: scroll;
      //background: skyblue;
      &::-webkit-scrollbar {
        width: 0px;
      }
      .topImg{
        width: 100%;
        height: 400px;
        margin-bottom: 30px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .bottom{
        height: 390px;
        border: 1px solid #337D8D;
        display: flex;
        .b_left{
          width: 710px;
          height: 100%;
          background: #337D8D;
          padding: 45px 40px 25px;
          .l_title{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 25px;
            //line-height: 37px;
            span{
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.49);
              //line-height: 39px;
            }
          }
          .form{
            .inp{
              /deep/.el-input{
                width: 320px;
                height: 56px;
                input{
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .tex{
              /deep/.el-textarea{
                height: 140px;
                textarea{
                  min-height: auto;
                  height: 100%;
                  resize: none;
                }
              }
            }
            .btn{
              background: #337D8D;
              border: 1px solid #FFFFFF;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              padding: 7px 32px;
              float: right;
            }
          }
        }
        .b_right{
          width: 710px;
          height: 100%;
          background: rgba(255, 255, 255, 0.59);
          padding: 40px;
          .r_title{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 37px;
          }
          .r_title_text{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 39px;
          }
          .wire{
            border-top: 1px dashed #DFDFDF;
            margin: 10px 0 20px;
          }
          .contact{
            display: flex;
            .c_info{
              width: 240px;
              div{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 40px;
                span{
                  color: #000000;
                  margin-left: 10px;
                }
              }
            }
            .c_info1{
              margin-right: 60px;
            }
          }
          .path{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            //line-height: 45px;
            margin-top: 35px;
            span{
              margin-left: 10px;
              color: #000000;
            }
          }

        }
      }

    }
  }
  .fontTaggle{
    position: absolute;
    left: 40px;
    bottom: 50px;
    display: flex;
    .font{
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      color: #666666;
      border: 1px solid #666666;
      border-radius: 27px;
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      &.active{
        background: #337D8D;
        color: #FFFFFF;
      }
    }
  }
}
</style>



