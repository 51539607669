<template>
  <div class="page">
    <div class="title" v-if="tType == 'CH'">度假区荣誉</div>
    <div class="title" v-if="tType == 'EN'">Resort Honors</div>
    <div class="pageItem">
      <div class="special-2">
        <div class="emptyPage" v-if="list.length==0"></div>
        <div v-for="item of list" :key="item.aboutasId" class="special-2-item" @click="go(item.aboutasId)">
          <div class="special-2-item-img">
            <img :src="item.aboutasCover" style="border-radius: 20px 20px 0 0; " width="100%" height="100%" alt="">
            <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
          </div>
          <div class="special-2-item-text">
            <div class="special-2-item-title">{{item.aboutasTitle}}</div>
            <div class="text2"><span v-for="(items,index) in item.tags">{{items}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {aboutasList, imgURL} from '@/api'
export default {
  name: "homefive",
  components:{
    // Navbar,
  },
  data() {
    return {
      tType:'CH',
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      fontToggle: '1',
      imgURL,
      list:[
        // {
        //   id:1,
        //   imagespath:require('@/assets/img/s-tu.png'),
        //   title:'运河绝版地，江南水弄堂'
        // }
      ],
      total:0,
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.createlist()
    // sessionStorage.setItem('RightImg',require('../../../public/img/swiperPage/DTZXCBT.png'))
  },
  methods:{
    go(id){
      this.$router.push({path:'/we/honorInfo',query:{id}})
    },
    createlist(){
      aboutasList({
        aboutasType:2
      }).then(res=>{
        console.log(res)
        if(res.code==200){
          this.list=res.rows
          this.list.forEach((item,index)=>{
            if(item.tags&&item.tags.trim().length>0){item.tags = item.tags.split(',')}
            // item.tags = item.tags.split(',')
          })
        }
      })
    },
    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createlist()
    },
    itembtn(id){
      this.$router.push({path:'/travelStrategy/travelStrategyTwoInfo',query:{type:JSON.stringify({title:'产品详情',type:"five",id})}})
    },
  }
}
</script>

<style scoped lang="less">
/deep/.title {
  position: absolute;
  top: -170px;
  left: 50%;
  margin-left: -100px;
  color: #FFFFFF;
  font-size: 40px;
  font-family: SimSun;
  font-weight: 700;
  z-index: 100;
}
.gengduo{
  width: 160px;
  height: 48px;
  border: #007B8A 1px solid;
  color: #007B8A;
  text-align: center;
  line-height: 48px;
  float: right;
  border-radius: 30px;
  margin-right: 150px;
}
.page{
  position: relative;
  height: 100%;
  margin-top: -100px;
  z-index: 111 !important;
  // 页面主体
  .pageItem{
    overflow: hidden;
    display: flex;
    //align-items: center;
    //justify-content: space-between;
    box-sizing: border-box;
    .left{
      width: 235px;
      height: 700px;
      padding-top: 55px;
      margin-right: 60px;
      margin-left: 55px;
      //background: yellow;
      .top{
        width: 100%;
        height: 500px;
        img{
          width: 100%;
        }
      }
      .more{
        width: 18px;
        height: 130px;
        border-radius: 27px;
        border: 1px solid #337D8D;
        text-align: center;
        //line-height: 130px;
        padding: 0 20px;
        box-sizing: content-box;
        margin: auto;
        div{
          height: 130px;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          line-height: 20px;
          color: #337D8D;
        }
      }
    }
    /deep/.special-2 {
      width: 1300px;
      height: 100%;
      margin: 0 auto;
      background: #FFFFFF;
      padding: 65px 0;
      box-sizing: border-box;
      border-radius: 40px;

      .special-2-item {
        width: 380px;
        height:380px;
        background: #ffffff;
        border-radius: 20px;
        display: inline-block;
        margin: 25px;
        cursor: pointer;
        &:hover{
          cursor:pointer;
          .special-2-item-text .special-2-item-title{
            color: #337D8D;
          }
          .special-2-item-img img{
            transform: scale(1.1);
          }
        }

        .special-2-item-img {
          width: 380px;
          height:300px;
          overflow: hidden;
          position: relative;
          border-radius: 15px 15px 0 0;
          img{
            width: 100%;
            height: 100%;
            border-radius: 15px 15px 0 0;
            transition:all 500ms ease;
            transform: scale(1);
          }
          .eye{
            position: absolute;
            top: 16px;
            left: 16px;
            padding: 8px 12px;
            background: #337D8D55;
            border-radius: 5px;
            font-size: 14px;
            font-family: STHeitiSC-Medium, STHeitiSC;
            font-weight: 600;
            color: #ffffff;
          }
        }

        .special-2-item-text {
          padding: 24px;
          .special-2-item-title {
            width: 300px;
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
            line-height: 28px;
            /* 文本不换行 */
            white-space: nowrap;
            /* 超出范围隐藏 */
            overflow: hidden;
            /* 文字超出用省略号 */
            text-overflow: ellipsis;
          }
          .text2{
            margin: 15px 0;
            span{
              font-size: 12px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #333333;
              padding: 3px 13px 4px 12px;
              margin-left: 10px;
              background: rgba(120, 120, 120, 0.1);
              border-radius: 17px;
              &:nth-child(1){
                margin-left: 0;
              }
            }
          }

          .special-2-item-value {
            width: 300px;
            font-size: 14px;
            color: #808080;
            /* 在恰当的断字点进行换行 */
            word-break: break-all;
            /* 超出范围隐藏 */
            overflow: hidden;
            /* 文字超出用省略号 */
            text-overflow: ellipsis;
            /* 盒子模型 */
            display: -webkit-box;
            /* 显示的文本行数 */
            -webkit-line-clamp: 2;
            /* 子元素的垂直排列方式 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .emptyPage{
    height: 500px;
  }
  .fontTaggle{
    position: absolute;
    left: 40px;
    bottom: 50px;
    display: flex;
    .font{
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      color: #666666;
      border: 1px solid #666666;
      border-radius: 27px;
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      &.active{
        background: #337D8D;
        color: #FFFFFF;
      }
    }
  }
}
</style>

