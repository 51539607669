<template>
  <div class="content"
       v-infinite-scroll="loadMore"
       :infinite-scroll-disabled="busy"
       :infinite-scroll-distance="50"
       :infinite-scroll-immediate="false"
  >
    <div class="title" v-if="tType == 'CH'">古运河度假区大事记</div>
    <div class="title" v-if="tType == 'EN'">Memorabilia of the ancient Canal Resort</div>
    <div class="block">
      <el-timeline>
        <el-timeline-item v-for="(item) in list" color="#0bbd87" icon="el-icon-more" :timestamp="item.aboutasCreateTime" placement="top">
          <el-card>
            <div class="item" @click="go(item.aboutasId)">
<!--              暂时隐藏-->
<!--              <div class="item_img scale">
                <img :src="item.aboutasCover" class="imgs" alt="">
                <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
              </div>-->
              <div class="item_val" style="padding-left: 0;">
                <div class="item_title">
                  <div class="tit">{{item.aboutasTitle}}</div>
<!--                  <div class="time">{{item.aboutasCreateTime || ''}}</div>-->
                </div>
<!--                <div class="item_num el-icon-view"> &nbsp;{{item.visitNum || 0}}</div>-->
<!--                <div class="item_time">{{item.aboutasCreateTime || ''}}</div>-->
                <div class="text2"><span v-for="(items,index) in item.tags">{{items}}</span></div>
                <div class="item_text">{{item.aboutasDesc}}</div>
              </div>
            </div>
          </el-card>
        </el-timeline-item>
<!--        <el-timeline-item color="#0bbd87" icon="el-icon-more" timestamp="2021/12/06" placement="top">-->
<!--          <el-card>-->
<!--            <div class="item">-->
<!--              <div class="item_img">-->
<!--                <img src="@/assets/img/WT2.png" width="100%" height="100%" alt="">-->
<!--              </div>-->
<!--              <div class="item_val">-->
<!--                <div class="item_title">无锡上榜！首批国家级旅游休闲街区！</div>-->
<!--                <div class="item_text">今天我国首批国家级旅游休闲街区名单公布，经评审，第一批名单共有55个街区，其中，江苏省有南京市秦淮区夫子庙步行街、无锡市梁溪区清名桥历史文化街区、苏州市姑苏区平江历史街区三地上榜</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-card>-->
<!--        </el-timeline-item>-->
      </el-timeline>
    </div>
  </div>
</template>

<script>
import {aboutasList} from "@/api";
export default {
name: "deeds",
  data(){
    return {
      tType:'CH',
      list:[],
      totalCount: 0,
      current_page: 1,
      per_page: 10,
      busy: false,
      timer:null
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.getList()
  },
  methods:{
    loadMore: function() {
      clearTimeout(this.timer)
      this.busy = true
      if(this.totalCount > this.list.length){
        this.timer = setTimeout(()=>{
          this.current_page++
          this.getList()
        },1000)
      }else{
        return
      }

    },
    go(id){
      return false
      this.$router.push({path:'/we/indexInfo',query:{id}})
    },
    getList(){
      if(this.current_page == 1){
        this.list = []
      }
      aboutasList({
        aboutasType:1,
        pageNum:this.current_page,
        pageSize:this.per_page
      }).then(res=>{
        // console.log(res)
        if(res.code==200){
          res.rows.forEach((item,index)=>{
            if(item.tags&&item.tags.trim().length>0){item.tags = item.tags.split(',')}
            // item.tags = item.tags.split(',')
          })
          this.list.push(...res.rows)
          this.busy = false
          this.totalCount=res.total
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content{
  width: 1300px;
  margin: 0 auto;
  .title{
    position: absolute;
    top: 0;
    left: 50%;
    //margin-left: -200px;
    color: #FFFFFF;
    font-size: 40px;
    line-height: 300px;
    font-family: SimSun;
    font-weight: 700;
    z-index: 100;
    transform: translateX(-50%);
    //margin-top: 50px;
    //margin-bottom: 50px;
    //font-size: 34px;
    //font-family: STSongti-SC-Black, STSongti-SC;
    //font-weight: 900;
    //color: #333333;
    //line-height: 48px;
  }
  /deep/.block{
    width: 100%;
    background: #fff;
    padding: 40px;
    border-radius: 40px;
    position: relative;
    margin-top: -100px;
    z-index: 111;
  }
  .item{
    display: flex;
    flex-wrap: nowrap;
    .item_img{
      width: 250px;
      height: 150px;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      .imgs{
        width: 100%;
        height: 100%;
      }
      .eye{
        position: absolute;
        top: 16px;
        left: 16px;
        padding: 8px 12px;
        background: #337D8D55;
        border-radius: 5px;
        font-size: 14px;
        font-family: STHeitiSC-Medium, STHeitiSC;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .item_val{
      //width: calc(100% - 300px);
      padding-left: 30px;
      box-sizing: border-box;
      .item_title{
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit{
          font-size: 24px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 700;
          color: #333333;
          max-width: 80%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //行数
           overflow: hidden;
        }
        .time{
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
        }
      }
      .text2{
        margin: 15px 0;
        span{
          font-size: 12px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #333333;
          padding: 3px 13px 4px 12px;
          margin-left: 10px;
          background: rgba(120, 120, 120, 0.1);
          border-radius: 17px;
          &:nth-child(1){
            margin-left: 0;
          }
        }
      }
      .item_num,.item_time{
        font-size: 16px;
        color: #999999;
        margin: 5px 0;
        //width: 98%;
      }
      .item_text{
        font-size: 18px;
        line-height: 23px;
        color: #808080;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 2; //行数
        // overflow: hidden;
      }
    }
  }
  /deep/.el-card{
    background: #E1ECEE!important;
  }
  /deep/.el-timeline-item__timestamp{
    font-size: 20px;
    margin: 20px 0;
  }
}
</style>
