<template>
  <div>
    <div class="titl" v-if="tType == 'CH'">关于我们</div>
    <div class="titl" v-if="tType == 'EN'">About Us</div>
    <div class="content">
      <div class="us">
        <div class="title" v-if="tType == 'CH'">联系我们</div>
        <div class="title" v-if="tType == 'EN'">contact us</div>
        <div class="context">
          <div class="left">
            <div v-for="(item,index) in list"><span>{{item.cnName}}:</span>{{ item.content }}</div>
<!--            <div><span>投诉电话:</span>0510-XXXXXXX</div>-->
<!--            <div><span>救助电话:</span>0510-XXXXXXX</div>-->
          </div>
<!--          <div class="right">-->
<!--            <div><span>传真:</span>0510-XXXXXXX</div>-->
<!--            <div><span>邮编:</span>0510-XXXXXXX</div>-->
<!--            <div><span>邮箱:</span>0510-XXXXXXX</div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="complaint">
        <div class="titless" v-if="tType == 'CH'">度假区投诉</div>
        <div class="titless" v-if="tType == 'EN'">Resort Complaint</div>
        <el-input v-model="phone" class="phone" :placeholder="tType == 'CH'?'请输入联系方式':'Please enter contact information'"></el-input>
        <el-input
            type="textarea"
            :rows="8"
            :placeholder="tType == 'CH'?'请输入您的投诉内容':'Please enter your complaint content'"
            v-model="content">
        </el-input>
        <div class="btn" @click="enter" v-if="tType == 'CH'">提交</div>
        <div class="btn" @click="enter" v-if="tType == 'EN'">submit</div>
      </div>
    </div>
  </div>
</template>

<script>
import {usList,consultAdd} from '@/api/index'
export default {
name: "contact.vue",
  data() {
    return {
      tType:'CH',
      phone:'',
      content:'',
      list:[]
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.getUsList()
  },
  methods:{
    async getUsList(){
      await usList({}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.list=res.rows
        }
      })
    },
    async enter(){
      if(!this.phone){
        this.$message({
          message: this.tType == 'EN' ? 'Please enter contact information':'请输入联系方式',
          type: 'warning'
        });
        return
      }
      var tel = /^(0\d{2,3}\-)?([2-9]\d{6,7})+(\-\d{1,6})?$/;
      var phone = /^((\+86|\+86\-)|(86|86\-)|(0086|0086\-))?1[3|5|7|8]\d{9}$/;
      if((this.phone.length != 11 || !(phone.test(this.phone)))
          && (!(this.phone.length == 13 && this.phone.indexOf("-") != -1) || !(tel.test(this.phone)))
      ) {//  手机号和座机号
        this.$message({message: this.tType == 'EN' ? 'Please enter the correct contact information' :'请输入正确联系方式',type: 'warning'});
        return false
      }
      // console.log('通过')
      // return false
      if(!this.content){
        this.$message({
          message: this.tType == 'EN' ? 'Please enter the content of the complaint' : '请输入要投诉的内容',
          type: 'warning'
        });
        return
      }

      await consultAdd({
        content:this.content,
        phone:this.phone
      }).then(res=>{
        console.log(res)
        if(res.code==200){
          this.$message({
            message: this.tType == 'EN' ? 'submit successfully' : '提交成功',
            type: 'success'
          });
          this.content=''
          this.phone=''
        }else{
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.titless{
  font-size: 34px;
  font-family: STSongti-SC-Black, STSongti-SC;
  font-weight: 900;
  color: #333333;
  line-height: 48px;
  margin-bottom: 30px;
  margin-top: 10px;
}
/deep/.titl {
  position: absolute;
  top: 130px;
  left: 0;
  width: 100%;
  text-align: center;
  //margin-left: -200px;
  color: #FFFFFF;
  font-size: 40px;
  font-family: SimSun;
  font-weight: 700;
  z-index: 100;
}
.content{
  width: 1300px;
  height: 100%;
  margin: -100px auto 200px auto;
  padding: 80px 0;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 40px;
  position: relative;
  z-index: 111;
  .us{
    width: 100%;
    .title{
      font-size: 34px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #333333;
      line-height: 48px;
      padding-left: 50px;
      margin-bottom: 30px;
      margin-top: 10px;
    }
    .context{
      width: 100%;
      height: 200px;
      background: #fff;
      display: flex;
      flex-wrap: nowrap;
      padding: 40px 50px;
      .left,.right{
        width: 100%;
        //display: flex;
        //justify-content: space-between;
        div{
          width: 50%;
          height: 40px;
          float: left;
          line-height: 40px;
          span{
            color: #999999;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .complaint{
    width: 100%;
    margin-top: 40px;
    padding: 0 50px 60px;
    .title{
      font-size: 34px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #333333;
      line-height: 48px;
      padding-left: 50px;
      margin-bottom: 30px;
      margin-top: 10px;

    }
    .phone{
      width: 330px;
      height: 56px;
    }
    .btn{
      width: 100px;
      height: 40px;
      background: #337D8D;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: STSongti-SC-Regular, STSongti-SC;
      font-weight: 400;
      color: #FFFFFF;
      float: right;
      margin-top: 30px;
      cursor: pointer;
    }
  }
}
</style>
