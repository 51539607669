<template>
  <div class="headerNavbar">
    <div class="log">
      <img src="" alt="">
    </div>
  </div>
</template>

<script>
import {LoguotUser, reviewedTotal, editPassword} from "@/api";

export default {
  name: "index",
  props: ['titleText'],
  data() {
    return {

    }
  },
  mounted() {


  },
  methods: {

  }
}
</script>

<style scoped lang="less">
  .headerNavbar{
    width: 100%;
    //height: 70px;
    //background: pink;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 25px 55px 10px 55px;
    .log{
      width: 175px;
      height: 75px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
